export default [
  {
    path: '/services',
    name: 'services',
    component: () => import(/* webpackChunkName: "Baselayout" */ '@/layout/BaseLayout.vue'),
    meta: {
      title: '我們的服務',
      siteMap: {
        title: '我們的服務',
        show: true,
        order: 2,
      },
    },
    children: [
      {
        path: 'medical-allowance',
        name: 'medical-allowance',
        component: () => import(/* webpackChunkName: "MedicalAllowance" */ '@/views/Services/MedicalAllowance.vue'),
        meta: {
          title: '醫療補助',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'prevent',
        name: 'prevent',
        component: () => import(/* webpackChunkName: "Prevent" */ '@/views/Services/Prevent.vue'),
        meta: {
          title: '早產防治暨安胎示範病房',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'communication-service-and-propaganda',
        name: 'communication-service-and-propaganda',
        component: () => import(/* webpackChunkName: "CommunicationServiceAndPropaganda" */ '@/views/Services/CommunicationServiceAndPropaganda.vue'),
        meta: {
          title: '早產兒社區服務與宣導',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'discharged-low-weight-checking',
        name: 'discharged-low-weight-checking',
        component: () => import(/* webpackChunkName: "DischargedLowWeightChecking" */ '@/views/Services/DischargedLowWeightChecking.vue'),
        meta: {
          title: '極低出生體重出院後追蹤檢查',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'team-training',
        name: 'team-training',
        component: () => import(/* webpackChunkName: "TeamTraining" */ '@/views/Services/TeamTraining.vue'),
        meta: {
          title: '醫療團隊教育訓練',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'experience-share',
        name: 'experience-share',
        component: () => import(/* webpackChunkName: "ExperineceShare" */ '@/views/Services/ExperineceShare.vue'),
        meta: {
          title: '合約醫院社工師',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'collaborate-hospital',
        name: 'collaborate-hospital',
        component: () => import(/* webpackChunkName: "CollaborateHospital" */ '@/views/Services/CollaborateHospital.vue'),
        meta: {
          title: '合約醫院',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },
      {
        path: 'form-download',
        name: 'form-download',
        component: () => import(/* webpackChunkName: "FormDownload" */ '@/views/Services/FormDownload.vue'),
        meta: {
          title: '表單/文件下載',
          banner: {
            subTitle: '我們的服務',
            bgName: 'banner-service',
          },
        },
      },

    ],
  },
];
